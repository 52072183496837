//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { STable, XCard } from '@/components'

export default {
  components: {
    XCard,
    STable
  },

  data () {
    const TableOneResule1 = { 'pageNo': 1, 'pageSize': 10, 'totalPage': 1, 'totalRows': 3, 'rows': [{ 'id': '1', 'name1': '測試1', 'name2': '測試2', 'remark': '備註' }, { 'id': '2', 'name1': '測試1', 'name2': '測試2', 'remark': '備註' }, { 'id': '3', 'name1': '測試1', 'name2': '測試2', 'remark': '備註' }] }
    const TableOneResule2 = { 'pageNo': 1, 'pageSize': 10, 'totalPage': 1, 'totalRows': 2, 'rows': [{ 'id': '1', 'name1': '2222', 'name2': '3333', 'remark': '備註' }, { 'id': '2', 'name1': 'erere', 'name2': '測試2', 'remark': '備註' }] }

    return {
      // 查詢參數
      queryParam1: {},
      queryParam2: {},
      // 表頭
      columns1: [
        {
          title: '名稱1',
          dataIndex: 'name1'
        },
        {
          title: '名稱2',
          dataIndex: 'name2'
        },
        {
          title: '備註',
          dataIndex: 'remark'
        }
      ],
      // 表頭
      columns2: [
        {
          title: '名稱1',
          dataIndex: 'name1'
        },
        {
          title: '名稱2',
          dataIndex: 'name2'
        },
        {
          title: '備註',
          dataIndex: 'remark'
        }
      ],
      // 加載數據方法 必須為 Promise 對象
      loadData1: parameter => {
        return new Promise((resolve, reject) => {
          resolve(TableOneResule1)
        })
      },
      // 加載數據方法 必須為 Promise 對象
      loadData2: parameter => {
        return new Promise((resolve, reject) => {
          resolve(TableOneResule2)
        })
      },
      selectedRowKeys: [],
      selectedRows: []
  }
  },

  created () {
    this.columns1.push({
      title: '操作',
      width: '150px',
      dataIndex: 'action',
      scopedSlots: { customRender: 'action' }
    })
    this.columns2.push({
      title: '操作列',
      width: '150px',
      dataIndex: 'action',
      scopedSlots: { customRender: 'action' }
    })
  },

  methods: {
    handleOk () {
      this.$refs.table.refresh()
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    }
  }

}
